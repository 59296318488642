<template>
    <div class="done-detail-base">
        <div class="task-content">
            <div class="task-item">
                <div class="task-label">任务名称：</div>
                <div class="task-p">{{detailData.RWMC}}</div>
            </div>
            <div class="task-item">
                <div class="task-label">任务描述：</div>
                <div class="task-p">{{detailData.RWMS}}</div>
            </div>
            <div class="task-item">
                <div class="task-label">任务对象：</div>
                <div class="task-p">{{detailData.JCDXMC}}</div>
            </div>
            <div class="task-item">
                <div class="task-label">指派人：</div>
                <div class="task-p">{{detailData.ZBRXM}}</div>
            </div>
            <div class="task-item">
                <div class="task-label">指派时间：</div>
                <div class="task-p">{{detailData.RWSCSJ}}</div>
            </div>
        </div>
        <AssessResult :dataSource="dataSource"></AssessResult>
    </div>
</template>
<script>
import {mapActions} from 'vuex';
import AssessResult from '@views/expert_consultation/components/assess-result'
export default {
    data() {
        return {
            detailData: {
                RWMC: '',
                RWMS: '',
                JCDXMC: '',
                ZBRXM: '',
                RWSCSJ: '2020-03-19'
            }
        }
    },
    props: {
        dataSource: {
            type: Object,
            default: () => { }
        }
    },
    watch: {
        dataSource() {
            this.getDetailData();
        }
    },
    mounted() {
        this.getDetailData();
    },
    components: {
        AssessResult
    },
    methods: {
        ...mapActions([
            'GetExpertAnalysisDetails', //获取已分析评估记录详情
        ]),
        async getDetailData(){
            this.detailData = {};
            if(this.dataSource && this.dataSource.ID){
                let result = await this.GetExpertAnalysisDetails({
                    rwid: this.dataSource.ID
                });
                if(result && result.length>0){
                    this.detailData = result[0];
                }
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.done-detail-base {
    width: 100%;
    padding: 10px;
    .task-content {
        padding: 0 10px 10px;
        border: 1px solid rgba(0,0,0,0);
        .task-item {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            margin-bottom: 10px;
            .task-label {
                width: 110px;
                padding-right: 5px;
                text-align: right;
                font-weight: bold;
            }
            .task-p {
                width: calc(100% - 100px);
                line-height: 24px;
            }
        }
    }
}
</style>
