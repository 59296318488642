<template>
    <div class="done-record-taskList">
        <p class="taskTitle">已分析评估记录</p>
        <p class="num">{{doneTotalAssess}}项</p>
        <el-pagination class="commonGridPage" small @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next" :total="doneTotalAssess" :pager-count="5" :hide-on-single-page="true">
        </el-pagination>
        <div class="tableBorder commGridTable">
            <el-table stripe highlight-current-row :data="doneDataAssess" ref="table" @row-click="rowClick" max-height="325" @current-change="handleCurrentRowChange">
                <el-table-column prop="RWMC" label="名称" minWidth="200px" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="CJSJ" label="评估时间" width="160px">
                </el-table-column>
                <el-table-column prop="CJRMC" label="评估人" width="80px" show-overflow-tooltip>
                </el-table-column>
                <!-- <el-table-column label="操作" width="80px">
                        <template slot-scope="scope">
                            <p class="fileText" @click="handleViewClick(scope.row)">查看</p>
                        </template>
                    </el-table-column> -->
            </el-table>
        </div>
        <sn-dialog :dialogData.sync="dialogData">
            <template>
                <DoneDetail :dataSource="currentData"></DoneDetail>
            </template>
        </sn-dialog>
    </div>
</template>
<script>
import DoneDetail from "./done-detail"
import { mapActions, mapGetters, mapMutations } from "vuex";
import { doneDataAssess, doneTotalAssess, SET_SELECTDATA,selectData } from "@store/modules/mutation-type.js";
export default {
    name: "done-record",
    data() {
        return {
            currentPage: 1, //当前页
            pageSize: 5, //每页条数
            dialogData: {
                title: '已分析评估记录详情',
                dialog: false,
                width: '',
                class: 'deepBlueDialog',
                action: false,
            },
            currentData: {},
            // selectedId: "",
        };
    },
    components: {
        DoneDetail
    },
    computed: {
        ...mapGetters({
            doneDataAssess,
            doneTotalAssess,
            selectData
        })
    },
    created() {
        this.getDoneData();
    },
    beforeDestroy() {
        this.SET_SELECTDATA({});
    },
    watch: {
        doneDataAssess() {

        },
        selectData(){
            if(this.selectData && this.selectData.pgZt === 'undone'){
                this.setCurrent();
            }
        },
    },
    methods: {
        ...mapActions([
            'GetPgjgList', //获取历史评估结果列表
        ]),
        ...mapMutations({
            SET_SELECTDATA
        }),
        handleViewClick(val) {
            this.dialogData.dialog = true;
            this.currentData = val;
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getDoneData();
        },
        rowClick(row) {
            if (!row) return;
            // this.$emit("rowClick", row);
        },
        handleCurrentRowChange(val) {
            if(!val) return;
            let obj = {
                pgZt: 'done',
            }
            Object.assign(obj, val);
            this.SET_SELECTDATA(obj);
        },
        setCurrent(row) {
            this.$refs.table.setCurrentRow(row);
        },
        async getDoneData() {
            this.GetPgjgList({
                Cjrid: localStorage.userId,
                pageindex: this.currentPage,
                pagesize: this.pageSize
            })
        }
    },
};
</script>
<style lang="scss" scoped>
.done-record-taskList {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: none;

    .taskTitle {
        height: 25px;
        font-size: 16px;
        color: #333;
    }

    .num {
        font-size: 13px;
        position: absolute;
        top: 4px;
        right: 0;
    }
    .commonGridPage {
        position: absolute;
        top: 0;
        right: 40px;
    }

    .tableBorder {
        margin-top: 10px;
        height: calc(100% - 50px);
    }

    .fileText {
        color: #4990e2;
        text-decoration: underline;
        cursor: pointer;
    }
}
</style>

