<template>
    <div class="analysis-assess-layout-container">
        <div class="banner">
            <div class="bannerContent">
                <img class="img" src="@image/expert_consultation/taskAlert.png">
                <span class="txt">您有</span>
                <span class="taskNum">{{taskNum}}</span>
                <span class="txt">项待分析评估记录！</span>
            </div>
        </div>
        <div class="content">
            <div class="left scrollbar">
                <div class="list">
                    <slot name="list"></slot>
                </div>
                <div class="record">
                    <slot name="record"></slot>
                </div>
            </div>
            <div class="right">
                <slot name="detail"></slot>
            </div>
        </div>
    </div>
</template>
<script>
import { undoneTotalAssess } from "@store/modules/mutation-type.js";
import { mapGetters } from "vuex";
export default {
    name: "expert-layout",
    data() {
        return {

        };
    },
    computed: {
        ...mapGetters({
            undoneTotalAssess
        }),
        taskNum() {
            if (this.undoneTotalAssess) {
                return this.undoneTotalAssess;
            } else {
                return 0;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.analysis-assess-layout-container {
    width: 100%;
    height: 100%;
    padding: 20px 20px;
    background: #f7f9f4;
    .banner {
        background-color: #c8dee5;
        width: 27%;
        height: 40px;
    }

    .content {
        /*flex布局**/
        width: 100%;
        margin-top: 15px;
        height: calc(100% - 50px);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .left {
        width: 27%;
        min-width: 450px;
        height: 100%;
        min-height: 648px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .right {
        width: 0;
        height: calc(100% + 55px);
        margin-top: -55px;
        padding-left: 30px;
        flex: 1;
    }

    .list {
        width: 100%;
        height: calc(100% - 260px);
        min-height: 460px;
        padding-bottom: 10px;
    }

    .record {
        width: 100%;
        height: 260px;
    }

    .img {
        width: 32px;
        height: 32px;
        margin: 6px 10px 0 20px;
        float: left;
    }

    .txt {
        font-size: 15px;
        font-weight: 600;
        float: left;
        margin-left: 6px;
        margin-top: 11px;
    }

    .taskNum {
        font-size: 15px;
        font-weight: 600;
        color: red;
        float: left;
        margin-left: 6px;
        margin-top: 11px;
    }
}
</style>
